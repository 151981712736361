/* NAVABR */

 body{
     background-color:#121212;
     color: #ffffff;
     padding-right: 15px; 
     padding-left: 15px;
  } 
#root{
  background-color:#121212;
}
 /* /////////////// NAVBAR //////////////////////// */

/* .mynav a{
    background-color: red;
    color: green;
} */
.navbar{ margin: auto;}
.menu1{
    padding-top: 10px;
    background-color: #121212;
}
/* .logo{
    font-size: 30px;
    margin-left: 30px;
    font-weight: 700;
    letter-spacing: 3px;
} */

/* trying */
/* .logo{
 position: relative;
  display: block;
  color: white;
  font-size: 14px;
  font-family: "montserrat";
  text-decoration: none;
  margin: 30px 0;
  border: 2px solid #ff7675;
  padding: 14px 60px;
  text-transform: uppercase;
  overflow: hidden;
  transition: 1s all ease;
}
.logo::before{
  width: 100%;
  height: 0%;
}
.logo:hover::before{
  height: 100%;
} */


/* trying */

.navii li a{
    margin-right: 26px;
    font-weight: 400;
    font-size: 16px;

     /* position:relative;
  text-decoration:none;
  display:inline-block; */
}

.navii li a::after{
    /* background: black;
   content: '';
   display: block;
   width: 40px;
    height: 1px; */

    display:block;
  content: '';
  border-bottom: solid 1px green;  
  transform: scaleX(0);  
  transition: transform 250ms ease-in-out;
  transform-origin:100% 50%
}



.navii li a:hover::after{
    /* background: green;*/

    transform: scaleX(1);
  transform-origin:0 50%;
}

/* Skill about me Image */
.imgme{
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

/* NAVBAR  FOR MOBILE */

@media (max-width: 768px){

  .navii li a{
    text-align: center;
    font-size: large;
  }
  .navii li a::after{
    border-bottom: 1px solid #000;

  }
  .navii li a:hover{
    /* border-bottom: 1px solid #000; */
    color: green !important;
    /* background-color: green; */
  }
  .heading1{
    /* background-color: red; */
    padding-top: 170px;
    height: 820px;
    /* background-image: linear-gradient(rgba(0,0,0,0.75),rgba(0,0,0,0.75)) */

}

}
@media (min-width: 1440px){
  body{
/* max-width: 1440px; */
margin: auto;

 } 
  .heading1{
    /* background-color: red; */
    height: 820px !important;
    /* background-image: linear-gradient(rgba(0,0,0,0.75),rgba(0,0,0,0.75)) */

}
.footer h1{
  margin: 0 20px;
}
.footer p{
  margin: 0 20px;
}
}
@media (min-width: 1960px){
  body{
background-color:#303030
 } 
 }
@media (min-width: 1024px){
  .heading1{
    /* background-color: red; */
    padding-top: 120px;
    /* background-image: linear-gradient(rgba(0,0,0,0.75),rgba(0,0,0,0.75)) */

}

}
/* headin 1 */

.heading1{
    /* background-color: red; */
    padding-top: 170px;
    padding-left: 40px;
    height: 100vh;
    /* background-image: linear-gradient(rgba(0,0,0,0.75),rgba(0,0,0,0.75)) */

}

.hh h1{
    font-size: 3rem;
    font-weight: 500;
    /* text-transform: uppercase; */
    letter-spacing: 2px;
    text-align: center;
}
.hh h2{
        color: #fff;
    font-size: 2rem;
    text-align: center;
    width: 100%;
    line-height: 1.6;
}
.lasth{
    color: green;
    /* background-color: green; */
}
.lasth p{
  font-size: 45px;
  text-align: center;
}

.hh a{
    text-decoration: none;
    color: #ffffff;
    letter-spacing: 1px;
    margin-top: 50px;
    text-align: center;
}
.hello1{
  margin-top: 60px;
  text-align: center;
}
.hello1 a{
  /* margin-right: 60px;
  padding: 16px;
  padding-right: 40px;
  padding-left: 40px;
  background: transparent;
  border: 2px solid green;
  font-size: 27px; */

  width: 100px;
  padding: 15px 45px;
  text-align: center;
  margin: 20px 20px;
  border-radius: 25px;
  font-weight: bold;
  border: 2px solid green;
  background: transparent;
  color: #fff;;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.btn-contact{
  color: #000;
  margin-right: 60px;
}

.hello1 span{
  background: green;
  height: 100%;
  width: 0;
  border-radius: 25px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  transition: 0.5s;
}

.hello1 a:hover span {
  width: 100%;
}
.hello1 a:hover{
  border: none;
}


/* .hello1 a:hover{
  background: green;
  border: 2px solid green;


} */



/* .hh a:hover::after{ */
 
    /* background: black;
   content: '';
   display: block;
   width: 40px;
    height: 1px; */
   /* background: black;
    display:block;
  content: '';
 width: 130px;
    height: 1px;
  transform: scaleX(0);  
  transition: transform 250ms ease-in-out;
  transform-origin:100% 50%
}



.hh a:hover::after{
    background: green;

    transform: scaleX(1);
  transform-origin:0 50%;
} */



/* //////////////  SCROLL MOUSE (Learned from webs cifar css scroll down Animation) /////////////////// */

.scroll-mouse{
  height: 50px;
  padding-top: 50px;
  width: 30px;
  border: 2px solid white;
  position: absolute;
  left: 50%;
  bottom: -30px;
  border-radius: 40px;
  cursor: pointer;
}

.scroll-mouse::before, 
.scroll-mouse::after{
  content: '';
  position: absolute;
  top: 10%;
  left: 50%;
  border: 2px solid white;
  height: 10px;
  width: 10px;
  transform: translate(-50%,-100%) rotate(45deg);
  border-top: transparent;
  border-left: transparent;
  animation: scroll-mouse 1s ease-in-out infinite;
}

.scroll-mouse::after{
  top: 30%;
  animation-delay: .9s;
}

@keyframes scroll-mouse{
  0%{
    opacity: 0;
  }
  30%{
    opacity: 1;
  } 
  60%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    top: 90%;
  }
 
}


/* /////////////  ABOUT ME ///////////////////// */

.about-page{
  background-color: #f9f9f9;
  color: #000;
  /* height: 110vh; */
  padding-right: 50px;
  padding-left: 120px;
  padding-bottom: 70px;
}

.aboutme h1{
  font-size: 2rem;
  font-weight: 650;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  padding-top: 50px;
}
.aboutme h1::after{
  content: '';
  display: block;
  width: 3rem;
  height: 5px;
  background: green;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 5px;
}

.aboutme p{
    /* display: block; */
    text-align: center;
    color: #555;
    font-size: 1.3rem;
    font-weight: 420;
    max-width: 60rem;
    margin: auto;
    line-height: 1.6;
}

.row{
  padding-top: 50px;
}

.get-to-know-me h2{
   /* font-weight: 700;
    font-size: 1.7rem; */
    margin-bottom: .5rem;
}

.get-to-know-me p{
  font-size: 1.2rem;
    color: #666;
    /* max-width: 60rem; */
    line-height: 1.7;
    margin-bottom: 1rem;
}

.btn-contact{
  text-transform: uppercase;
    letter-spacing: 2px;
    display: inline-block;
    margin-top: 20px;
    padding: 15px 45px;
    font-weight: 700;
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgb(0 0 0 / 15%);
    transition: transform .3s;
    cursor: pointer;
    background: green;
    color: #fff;

}
.btn-contact:hover{
  color: #000;
}
.my-skills {
  text-align: center;
}

.my-skills h2{
   font-weight: 700;
    font-size: 1.7rem;
    margin-bottom: 3rem;
}

.my-skills p{
  padding: 0.5rem 1.2rem;
  display: flex;
  display: inline-block;
  /* padding: 1rem 2rem; */
    margin-bottom: 1rem;
    margin-right: 1rem;
    font-size: 1rem;
    background: rgba(153,153,153,.2);
    border-radius: 5px;
    font-weight: 600;
    color: #666;
    text-align: center;
}

.p-linkedin{
  color: green;
  font-size: 20px;
  font-weight: 600;
  /* text-decoration: underline; */
}
.p-linkedin:hover{
  text-decoration: underline;
  list-style: none;
  color: green;
}


/* ////////////// PROJECT PAGE //////////////////// */

.project-page{
  background-color: #ffffff;
  /* height: 100vh; */
  color: #000;
}
.project-img{
  text-align: center;
}

.project-img img{ 
   width: 700px;
    height: 530px;  
}

.project-description {
  max-width: 650px;
  padding-left: 40px;
  padding-top: 50px;
  padding-bottom: 170px;
}
.project-description h1{
   /* font-weight: 700;
    font-size: 1.8rem;
    margin-bottom: 1rem; */
    font-size: 36px;
  font-weight: 700;
  /* text-align: center; */
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.project-description p{
      font-size: 1.2rem;
    color: #666;
    max-width: 60rem;
    line-height: 1.7;
    margin-bottom: 1rem;
}

.btn-contact:hover{
  color: #fff;
  background: #000;
}
.btn-contact{
  text-align: center;
}


/* /////////////// CONTACT PAGE /////////////////// */

.contact-form p{
  max-width: 70%;
  color: white;
}

.contact-form a{
  text-decoration: none;
  color: green;
}

.contact-form a:hover{
  text-decoration: underline;
  
}

/* ////////////////// End /////////////////// */

/* .end{
  padding-right: 70px;
  padding-left: 70px;
} */
.footer h1{
  font-size: 1.6rem;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    font-weight: 700;
}
.footer p{
    margin-top:1.9rem;
    color: #eee;
    font-size: 1rem;
    line-height: 1.7;
}
.mysocial{
  text-align: center;
}

.mysocial h1{
  font-size: 1.8rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;
    text-align: center;
}

.mysocial a i{
  margin-top: 1.9rem;
   margin-right: 50px;
   font-size: 37px;
   text-align: center;
}

.mysocial a i:hover{
   color: white;
   background-color: black;
}

.last1{
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: rgb(150, 150, 150);
  margin-top: 50px;

}
.last{
  text-align: center;
  margin-top: 17px;
  font-size: 12px;
  font-weight: 300;
}
.last p,a{
  text-align: center;
} 
.last a{
  font-weight: 600;
  font-size: 15px;
}

/* ////////////// Social Contact */

.social-contacts{
  /* text-align: center; */
  margin-top: 50px;
  margin-left: 18px;
}
.social-contacts a i{
  font-size: 12px;
  font-weight: bold;
}

.link2{
  text-transform: uppercase;
  color: green !important;
}

/* .btn-dang{
  background-color: green;
  color: black;
}
.btn-dang:hover{
  background-color: green;
  color:white;
} */



/* /////////// MOBILE VERSION //////////////// */

@media (max-width: 1150px){
  .project-img img{ 
   width: 480px;
    height: 450px;  
}
.project-img {
    text-align: center;
    margin-top: 0px;
}
.project-img:first-child{
  margin-top: 15px;
}
.scroll-mouse{
  display: none;
  }
  .heading1{
    /* background-color: red; */
    padding: 80px 20px 120px;
    height: 100%;
    /* background-image: linear-gradient(rgba(0,0,0,0.75),rgba(0,0,0,0.75)) */

}
}

@media (max-width: 798px){

  /* HEADER */

  .hh h1{
    font-size: 1.7rem;
  }
  .hh h2{
    margin-top: 20px;
    font-size: 1.3rem;
  }
   

   .hello1 a,.social-contacts a i{
    font-size: 0.5rem;
  }
  .social-contacts a i{
    font-size: 0.6rem;
  }
  .mygit{
    display: none;
     margin: 5px 5px;
     margin-top: 40px;
  }
  

  

/* About Page */

.about-page{
  padding: 0px 20px
}

.aboutme, .get-to-know-me{
  text-align: center;
  /* padding-right: 45px; */
  max-width: 100%;
  /* padding-left: 45px; */
}

.aboutme h1{
  font-size: 1.5rem;

}

.btn-contact{
  text-align: center;
}
.get-to-know-me h2{
   
    font-size: 1.4rem;
    
}
.my-skills {
  margin-top: 50px;
  /* margin-right:190px ; */
  text-align: center;
}
.my-skills h2{
  margin-right: 0px;
  font-size: 1.4rem;
}
.my-skills {
  /* padding-right: 100px; */
}
.my-skills p{
  font-size: 0.9rem;
  padding: 5px 15px;
}

/* PRODCUT PAGE */

.project-description{
  text-align: center;
}

.project-description h1{
  font-size: 28px;
}
.project-description {
    max-width: 650px;
    padding-left: 16px;
    padding-bottom: 170px;
}
.project-img{
  text-align: center;
  margin-top: -150px;
}

.project-img img{ 
   width: 380px;
    height: 350px;  
}
/* contact  */

.contact-form p{
  max-width: 100%;
  font-size: 18px;
}

.footer h1{
  text-align: center;
}

.last{
  text-align: center;
  font-size: 12px;
  /* margin-right: 210px; */
  margin-left: -10px;
}
.last p {
font-size: 12px;
}
}
@media (max-width: 768px){

  .project-img img{ 
    width: 100%;
     height: none;  
 }
 .project-description {
  text-align: center;
  max-width: none;
  width: 100%;
  padding: 50px 40px 170px;
}
.btn-contact{
  margin-right: 0;
}
}
@media (max-width: 468px){
.gitlost{
  display: none;
}}